import React from 'react';
import { Radio, FormControl, Flex } from '@contentful/f36-components';

interface SortOptionsProps {
  onSortChange: (sortType: string) => void;
  sortType: string | null;
}

const SortOptions: React.FC<SortOptionsProps> = ({ onSortChange, sortType }) => {
  return (
    <FormControl>
      <FormControl.Label>Sort by</FormControl.Label>
      <Flex flexDirection="column" gap="spacingS">
        <Radio
          isChecked={sortType === 'alphabetical'}
          onChange={() => onSortChange('alphabetical')}
        >
          A-Z
        </Radio>
        <Radio isChecked={sortType === 'releaseDate'} onChange={() => onSortChange('releaseDate')}>
          Release Date (Ascending)
        </Radio>
        <Radio
          isChecked={sortType === '-releaseDate'}
          onChange={() => onSortChange('-releaseDate')}
        >
          Release Date (Descending)
        </Radio>
        <Radio isChecked={sortType === 'custom'} onChange={() => onSortChange('custom')}>
          Custom
        </Radio>
      </Flex>
    </FormControl>
  );
};

export default SortOptions;
