import { Flex, Text, Box } from '@contentful/f36-components';

import { NewReleasesType } from '../MainComponent/types';
import EditorsListItem from './components/EditorsListItem';
import useDropFn from 'hooks/useDropFn';
import { useEffect, useState } from 'react';

type EditorsTypes = {
  editors: NewReleasesType['editors'];
  allowedDropEffect: string;
  onDropItem: (albumCode: string) => void;
  onDeleteItem?: (albumCode: string) => void;
};

const EditorsList = ({ editors, allowedDropEffect, onDropItem, onDeleteItem }: EditorsTypes) => {
  const [editorPicks, setEditorPicks] = useState(editors);

  useEffect(() => {
    setEditorPicks(editors);
  }, [editors]);
  const moveItem = (fromIndex: number, toIndex: number) => {
    setEditorPicks(prev => {
      const updated = [...prev];

      if (fromIndex === toIndex) return updated;

      const [movedItem] = updated.splice(fromIndex, 1);
      updated.splice(toIndex, 0, movedItem);

      return updated;
    });
  };
  const handleDropItem = (albumCode: any) => {
    onDropItem(albumCode);
  };

  const { dropRef, containerStyles } = useDropFn({
    allowedDropEffect,
    type: ['releases'],
    onDrop: handleDropItem,
  });

  return (
    <Box style={{ flex: 1 }}>
      <Text fontSize="fontSizeL" style={{ marginBottom: '5px' }}>
        Editor's Picks
      </Text>
      <div
        ref={dropRef}
        style={{
          ...containerStyles,
          marginTop: 8,
          minHeight: 500,
          height: 'auto',
          overflowY: 'scroll',
        }}
      >
        <Flex flexDirection="column" padding="spacingXs">
          {editorPicks.map((editor: any, index: number) => {
            if (!editor) return null;
            return (
              <EditorsListItem
                key={editor?.id}
                value={editor}
                onDelete={onDeleteItem}
                onMove={moveItem}
                index={index}
              />
            );
          })}
        </Flex>
      </div>
    </Box>
  );
};

export default EditorsList;
