import { Box, Flex, Text, Stack, Button } from '@contentful/f36-components';
import { useEffect, useState, useCallback, useRef } from 'react';
import { getAlbumsByDate, getAlbumsByIds } from 'utils/api';
import { Album, MainComponentProps, NewReleasesType } from './types';
import ReleasesList from '../ReleasesList';
import EditorsList from '../EditorsList';
import SortOptions from 'components/SortRadioButtons';
import { formatDateForDisplay } from 'utils';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];
const MainComponent = ({ sdk }: MainComponentProps) => {
  const [newReleases, setNewReleases] = useState<NewReleasesType>({ editors: [], releases: [] });
  const [startDate, setStartDate] = useState<Value>(null);
  const [endDate, setEndDate] = useState<Value>(null);
  const [sortType, setSortType] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingReleases, setIsLoadingReleases] = useState<undefined | boolean>();
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [successSaved, setIsSuccessSaved] = useState<boolean>(false);
  const [errorSaved, setIsErrorSaved] = useState<boolean>(false);
  const startInputRef = useRef<HTMLInputElement>(null);

  const fetchGetAlbumsByDate = useCallback(async () => {
    try {
      setIsLoading(true);
      const albumsResult = await getAlbumsByDate(
        startDate instanceof Date ? startDate.toISOString().split('T')[0] : '',
        endDate instanceof Date ? endDate.toISOString().split('T')[0] : '',
      );

      return albumsResult;
    } catch (error) {
      console.error('🚀 ~ fetchGetAlbumsByDate ~ error', error);
    } finally {
      setIsLoading(false);
    }
  }, [startDate, endDate]);

  const fetchAlbumsByIds = async (ids: string[]) => {
    const albums = await getAlbumsByIds(ids);

    if (albums.length === 0) {
      console.warn('🚀 ~ fetchAlbumsByIds ~ No albums found');
      return [];
    }

    return albums;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!sdk.field) return;

      setIsLoadingReleases(true);

      try {
        const newReleases = sdk.field.getValue();
        if (!newReleases) return;

        const { releases = [], editors = [] } = newReleases;

        const [releasesAlbums, editorsPicksAlbums] = await Promise.all([
          releases.length > 0 ? fetchAlbumsByIds(releases) : Promise.resolve([]),
          editors.length > 0 ? fetchAlbumsByIds(editors) : Promise.resolve([]),
        ]);

        setNewReleases(prev => ({
          ...prev,
          releases: releasesAlbums,
          editors: editorsPicksAlbums,
        }));
      } catch (error) {
        console.error('🚀 ~ fetchData ~ Error fetching albums:', error);
      } finally {
        setIsLoadingReleases(false);
      }
    };

    fetchData();
  }, [sdk.field, setNewReleases]);

  useEffect(() => {
    if (successSaved) {
      setTimeout(() => {
        setIsSuccessSaved(false);
      }, 2500);
    }
  }, [successSaved]);

  useEffect(() => {
    if (errorSaved) {
      setTimeout(() => {
        setIsErrorSaved(false);
      }, 2500);
    }
  }, [errorSaved]);

  const handleEditorsDropItem = (albumCode: any) => {
    setIsSaveDisabled(false);
    setNewReleases((prevState: NewReleasesType) => {
      const currentEditors = prevState.editors;

      const alreadyExists = currentEditors.some((album: any) => album.id === albumCode.album.id);

      if (!alreadyExists) {
        return {
          ...prevState,
          editors: [...currentEditors, albumCode.album],
        };
      }

      return prevState;
    });
  };

  const handleDeleteEditorsItem = (albumCode: string) => {
    setIsSaveDisabled(false);
    setNewReleases((prevState: NewReleasesType) => ({
      ...prevState,
      editors: prevState.editors.filter((value: string) => value !== albumCode),
    }));
  };

  const handleDeleteReleasesItem = (album: any) => {
    setIsSaveDisabled(false);
    setNewReleases((prevState: NewReleasesType) => ({
      ...prevState,
      releases: prevState.releases.filter((value: Album) => {
        return value.albumCode !== album.albumCode;
      }),
    }));
  };

  const handleSaveClick = async () => {
    try {
      setIsSaveDisabled(true);
      setIsErrorSaved(false);
      setIsSuccessSaved(false);
      const data = {
        editors: newReleases.editors.map((editor: any) => editor.albumCode),
        releases: newReleases.releases.map((release: any) => release.albumCode),
      };
      await sdk.field.setValue(data);

      setIsSaveDisabled(false);
      setIsSuccessSaved(true);
    } catch (error) {
      setIsErrorSaved(true);
      setIsSaveDisabled(false);
    }
  };
  const onSubmitAlbums = async (sortType: string | null) => {
    const albums: Album[] = (await fetchGetAlbumsByDate()) || [];
    setNewReleases({ editors: [...newReleases.editors], releases: albums });
    let sortedAlbums = [...albums];

    switch (sortType) {
      case 'alphabetical':
        sortedAlbums.sort((a, b) => a.title.localeCompare(b.title));
        break;

      case 'releaseDate':
        sortedAlbums.sort(
          (a, b) => new Date(a.apm_release_date).getTime() - new Date(b.apm_release_date).getTime(),
        );
        break;

      case '-releaseDate':
        sortedAlbums.sort(
          (a, b) => new Date(b.apm_release_date).getTime() - new Date(a.apm_release_date).getTime(),
        );
        break;

      case 'custom':
      default:
        sortedAlbums = albums;
        break;
    }

    setNewReleases(prev => ({
      ...prev,
      releases: sortedAlbums,
    }));

    setIsSaveDisabled(false);
  };

  const handleChangeSort = (sortType: string | null) => {
    setSortType(sortType);
    setIsSuccessSaved(false);
  };
  const handleStartDateChange = (value: any) => {
    setStartDate(value);
    if (value) {
      const formattedDisplayDate = formatDateForDisplay(value);
      if (startInputRef.current) {
        startInputRef.current.value = formattedDisplayDate;
      }
      const date = new Date(value);
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth();
      const lastDateOfMonth = new Date(year, month + 1, 0);

      setEndDate(new Date(lastDateOfMonth));
    }
  };

  return (
    <div style={{ height: '100%' }}>
      <Text fontSize="fontSizeL" marginTop="spacingL">
        Matching Albums
      </Text>
      <Flex flexDirection="column" justifyContent="space-between">
        <Flex flexDirection="column">
          <Stack marginTop="spacingM" marginBottom="spacingM">
            <Box marginRight="spacingM">
              <Flex flexDirection="column">
                <Text>From</Text>

                <DatePicker onChange={handleStartDateChange} value={startDate} />
              </Flex>
            </Box>
            <Box>
              <Flex flexDirection="column">
                <Text>To</Text>
                <DatePicker onChange={setEndDate} value={endDate} />
              </Flex>
            </Box>
          </Stack>
        </Flex>
        <Flex flexDirection="column">
          {isLoadingReleases ? (
            <Text>Loading...</Text>
          ) : (
            <>
              <SortOptions onSortChange={handleChangeSort} sortType={sortType} />
              <Button
                variant="primary"
                style={{ marginBottom: '10px', width: '150px' }}
                onClick={() => onSubmitAlbums(sortType)}
                isDisabled={sortType === null || (startDate === null && endDate === null)}
              >
                Get Albums
              </Button>
              <Flex flexDirection="row" style={{ width: '100%' }}>
                <Flex
                  flexDirection="row"
                  justifyContent="space-between"
                  style={{ width: '100%' }}
                  gap="spacingM"
                >
                  <ReleasesList
                    releases={newReleases.releases}
                    allowedDropEffect="move"
                    onDeleteItem={handleDeleteReleasesItem}
                    sdk={sdk}
                    isLoading={isLoading}
                    currentSortType={sortType}
                    setNewReleases={setNewReleases}
                  />
                  <EditorsList
                    editors={newReleases.editors}
                    allowedDropEffect="move"
                    onDropItem={handleEditorsDropItem}
                    onDeleteItem={handleDeleteEditorsItem}
                  />
                </Flex>
              </Flex>

              <Box marginTop="spacingL">
                <Button variant="primary" onClick={handleSaveClick} isDisabled={sortType === null}>
                  Save
                </Button>
              </Box>
              <Box marginTop="spacingM">
                {successSaved && <Text>Saved</Text>}
                {errorSaved && <Text>Error</Text>}
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </div>
  );
};

export default MainComponent;
