import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL;
const headers = {
  'Content-Type': 'application/json',
};

export const getAlbumsByDate = async (startDate: string, endDate: string) => {
  try {
    const response = await axios.post(
      `${baseUrl}/search/albums`,
      {
        limit: '1000',
        offset: 0,
        sort: 'explore',
        si: 511,
        rdate: [startDate, endDate],
      },
      { headers },
    );

    const albums = response.data.rows || [];

    if (albums.length === 0) {
      console.warn('🚀 ~ getAlbumsByDate ~ No albums found');
      return [];
    }

    const albumsWithReleaseDate = await Promise.all(
      albums.map(async (album: any) => {
        try {
          const releaseDate = await fetchAlbumMetadata(album.id);
          return { ...album, apm_release_date: releaseDate };
        } catch (error) {
          console.error(`Error fetching release date for album ${album.id}:`, error);
          return { ...album, apm_release_date: null };
        }
      }),
    );

    return albumsWithReleaseDate;
  } catch (error) {
    console.error('Error fetching albums by date:', error);
    return [];
  }
};

export const getAlbumsByIds = async (ids: string[]) => {
  try {
    const response = await axios.post(`${baseUrl}/search/albums/by-ids`, ids, {
      headers,
    });

    const albums = response.data || [];

    if (albums.length === 0) {
      console.warn('🚀 ~ getAlbumsByIds ~ No albums found');
      return [];
    }

    const albumsWithReleaseDate = await Promise.all(
      albums.map(async (album: any) => {
        try {
          const releaseDate = await fetchAlbumMetadata(album.id);
          return { ...album, apm_release_date: releaseDate };
        } catch (error) {
          console.error(`Error fetching release date for album ${album.id}:`, error);
          return { ...album, apm_release_date: null };
        }
      }),
    );

    return albumsWithReleaseDate;
  } catch (error) {
    console.error('Error fetching albums:', error);
    return [];
  }
};
export const fetchAlbumMetadata = async (albumId: string) => {
  try {
    const response = await axios.get(`${baseUrl}/metadata/albums/${albumId}/all`);
    return response.data?.apm_release_date || null;
  } catch (error) {
    console.error(`Error fetching metadata for album ${albumId}:`, error);
    return null;
  }
};
