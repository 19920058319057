import { useEffect, useRef, useState } from 'react';
import { Text, Flex, Box } from '@contentful/f36-components';

import { NewReleasesType } from '../MainComponent/types';
import ReleasesListItem from './components/ReleasesListItem';
import { FieldExtensionSDK } from '@contentful/app-sdk';

type ReleasesTypes = {
  releases: NewReleasesType['releases'];
  allowedDropEffect: string;
  onDropItem?: (albumCode: any) => void;
  onDeleteItem?: (albumCode: string) => void;
  sdk: FieldExtensionSDK;
  isLoading: boolean;
  currentSortType: string | null;
  setNewReleases: (updateFn: (prevState: NewReleasesType) => NewReleasesType) => void;
};

const ReleasesList = ({
  releases: initialReleases,
  allowedDropEffect,
  onDeleteItem,
  sdk,
  isLoading,
  currentSortType,
  setNewReleases,
}: ReleasesTypes) => {
  const [releases, setReleases] = useState(initialReleases);

  useEffect(() => {
    setReleases(initialReleases);
  }, [initialReleases]);

  const currentSortTypeRef = useRef(currentSortType);

  useEffect(() => {
    currentSortTypeRef.current = currentSortType;
  }, [currentSortType]);

  const moveItem = (fromIndex: number, toIndex: number) => {
    if (currentSortTypeRef.current !== 'custom') return;

    setReleases(prev => {
      if (fromIndex < 0 || fromIndex >= prev.length || toIndex < 0 || toIndex >= prev.length) {
        return prev;
      }

      if (fromIndex === toIndex) return prev;
      const updated = [...prev];
      const [movedItem] = updated.splice(fromIndex, 1);
      updated.splice(toIndex, 0, movedItem);

      setNewReleases((prevState: NewReleasesType) => ({
        ...prevState,
        releases: updated,
      }));
      return updated;
    });
  };

  return (
    <Box flexDirection="column" style={{ flex: 1 }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text fontSize="fontSizeL">Albums</Text>
      </Box>
      <div style={{ marginTop: 8, height: '2000px', overflowY: 'scroll' }}>
        <Flex flexDirection="column" padding="spacingXs">
          {isLoading && <Text>Loading...</Text>}
          {releases.length === 0 && !isLoading && <Text>No albums </Text>}
          {releases.map((release, index) => (
            <ReleasesListItem
              key={release.id}
              value={release}
              index={index}
              onMove={moveItem}
              onDelete={onDeleteItem}
            />
          ))}
        </Flex>
      </div>
    </Box>
  );
};

export default ReleasesList;
