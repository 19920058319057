import { Flex, Text } from '@contentful/f36-components';
import { DeleteIcon } from '@contentful/f36-icons';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { formatDateForDisplay, getAlbumImageLink } from 'utils';

type EditorsListItemProps = {
  value: any;
  onDelete?: (value: string) => void;
  onMove: (fromIndex: number, toIndex: number) => void;
  index: number;
};

const EditorsListItem = ({ value, onDelete, onMove, index }: EditorsListItemProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'editors',
    item: () => ({ index, album: value }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ['releases', 'editors'],
    hover(draggedItem: { index: number; album: any }, monitor) {
      if (!ref.current) return;

      const dragIndex = draggedItem.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) return;

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      onMove(dragIndex, hoverIndex);

      draggedItem.index = hoverIndex;
    },
  });
  drag(drop(ref));

  return (
    <Flex
      ref={ref}
      marginTop="spacingXs"
      style={{
        cursor: 'grab',
        opacity: isDragging ? 0.5 : 1,
        padding: '8px',
        borderRadius: '4px',
        justifyContent: 'space-between',
      }}
    >
      <Flex flexDirection="row" alignItems="center">
        <img
          src={getAlbumImageLink(value.id)}
          alt={value.title}
          style={{ width: '50px', height: '50px' }}
        />
        <Flex flexDirection="column" marginLeft="spacingXs">
          <Text>{value.title}</Text>
          <Text>{value.albumCode}</Text>
          <Text>{formatDateForDisplay(value.apm_release_date)}</Text>
        </Flex>
      </Flex>
      <button
        onClick={() => onDelete?.(value)}
        style={{ backgroundColor: 'transparent', border: 'none' }}
      >
        <DeleteIcon style={{ cursor: 'pointer' }} variant="muted" />
      </button>
    </Flex>
  );
};

export default EditorsListItem;
